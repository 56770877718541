<template>
  <div class="app_con">
    <div class="head">
      <a href="https://pc.thread0.com" target="_blank"><img alt="logo图片" class="logo" src="../assets/img/logo.png"></a>
      <div class="head_nav active">经纬度转换</div>
    </div>
    <div class="banner_con">
      <swiper
          @swiper="onSwiper"
          :modules="modules"
          :autoplay="true"
          :loop="true"
      >
        <template v-for="(img,key) in bannerImgs" :key="key">
          <swiper-slide>
            <a href="https://pc.thread0.com" target="_blank"><img :alt="'Earth元地球下载轮播图'+key" class="banner_img"
                                                                  :src="img"/></a>
          </swiper-slide>
        </template>

      </swiper>
      <img alt="轮播图上一张" @click="controlledSwiper.slidePrev()" class="banner_controller"
           src="../assets/img/banner_previous.png"/>
      <img alt="轮播图下一张" @click="controlledSwiper.slideNext()" class="banner_controller"
           src="../assets/img/banner_next.png"/>
    </div>
    <div class="convert_con">
      <div class="convert_center">
        <h1 class="convert_title">「经纬度」和「度分秒」转换</h1>
        <p class="convert_introduce">
          经纬度在线格式转换工具，提供在线将经纬度的常用表示方式（如116.397026°）转换成为度分秒为单位的表示方式（如116°23′49.2936″）,或者反向转换换算；分隔符号不限中英文逗号和空格。</p>
        <div class="convert_div">
          <div class="left_convert">
            <template v-if="lonLatToDegConvert">
              <div class="unit_line"><p>经纬度:</p></div>
              <textarea :class="['text_div',errorMsg?'error_border':'']" style="resize: none" @input="leftTextConChange"
                        placeholder="例如:116.397026,39.918058 &#10;如需批量,可换行编辑"
                        v-model="leftText"></textarea>
            </template>
            <template v-else>
              <div class="unit_line"><p>度分秒:</p>
                <p class="dfm_div"><span @click="addUnitDot('°')">°</span><span @click="addUnitDot(`′`)">′</span><span
                    @click="addUnitDot('″')">″</span></p>
                <p class="copy_unit_tip">单击复制符号</p></div>
              <textarea :class="['text_div',errorMsg?'error_border':'']" style="resize: none" @input="leftTextConChange"
                        @mouseup="getTextAreaSelect" placeholder="例如:116°23′49.2936″,39°55′5.0088″ &#10;如需批量,可换行编辑"
                        v-model="leftText"></textarea>
            </template>

          </div>
          <div class="center_buttons">
            <div class="switch_div" @click="switchText">
              <img alt="交换按钮图片" src="../assets/img/switch.png"/>
              <p>交换</p>
            </div>
            <div class="clear_div" @click="clearText">
              <img alt="清空按钮图片暗" src="../assets/img/clear_dark.png" v-if="!leftText&&!errorMsg&&!rightText"/>
              <img alt="清空按钮图片亮" src="../assets/img/clear_bright.png" v-else/>
              <p>清空</p>
            </div>
          </div>
          <div class="right_convert">
            <div class="unit_line" v-if="!lonLatToDegConvert"><p>经纬度:</p></div>
            <div v-else class="unit_line"><p>度分秒:</p>
              <p class="dfm_div"><span @click="copyText('°')">°</span><span @click="copyText(`′`)">′</span><span
                  @click="copyText('″')">″</span></p>
              <p class="copy_unit_tip">单击复制符号</p></div>
            <textarea style="resize: none" class="text_div" readonly :value="this.rightText"></textarea>
          </div>
        </div>
        <div class="error_tip">{{errorMsg}}</div>
        <div class="result_buttons">
          <button :class="['get_result',leftText?'able':'']" @click="getResult()">开始转换</button>
          <div class="copy_result_div">
            <button class="copy_result" @click="copyResult">复制结果</button>
          </div>
        </div>
      </div>
    </div>
    <div class="foot_con">
      <div class="first_fool_line">
        <p>© 2022 厦门创世线程科技有限公司</p>
        <p><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备19003882号</a></p>
        <p><img alt="公安icon" src="../assets/img/police.png"/><a target="_blank"
                                                                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035155">闽公网安备35020302035155号</a>
        </p>
      </div>
      <div class="second_fool_line">
        <p>友情链接：<a href="https://pc.thread0.com" target="_blank">Earth元地球</a></p>
        <p>觉得好用记得收藏网址哦</p>
      </div>
    </div>
  </div>

</template>

<script>
import {ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay} from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css';
import BigNumber from "bignumber.js";
import 'element-plus/es/components/message/style/css'
import {message} from '../utils/resetMessage'

export default {
  name: "UnitConvert.vue",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const controlledSwiper = ref(null);
    const onSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };
    return {
      onSwiper,
      controlledSwiper,
      modules: [Autoplay]
    }
  },
  data() {
    return {
      bannerImgs: [
        "https://static.pc.earthdq.com/web/images/toolweb/banner/1.jpg",
        "https://static.pc.earthdq.com/web/images/toolweb/banner/2.jpg",
        "https://static.pc.earthdq.com/web/images/toolweb/banner/3.jpg",
      ],
      lonLatToDegConvert: true,
      leftText: null,
      rightText: null,
      errorMsg: null,
      leftTextAreaSelect: 0,
    }
  },
  methods: {
    getTextAreaSelect(ele) {
      this.leftTextAreaSelect = ele.target.selectionStart;
    },
    leftTextConChange() {
      this.leftTextAreaSelect = null
      this.errorMsg = null
    },
    //清空
    clearText() {
      this.leftText = null;
      this.rightText = null;
      this.errorMsg = null;
    },
    //单位切换
    switchText() {
      this.lonLatToDegConvert = !this.lonLatToDegConvert
    },
    //开始转换
    getResult() {
      this.rightText = null
      this.errorMsg = null
      let textareaLines = this.leftText.trim().split('\n')
      let resultArr = [];
      //经纬度转度
      if (this.lonLatToDegConvert) {
        for (let i = 0; i < textareaLines.length; i++) {
          let lonLatArr = textareaLines[i].split(/[，,\s]+/)
          //一个转换度
          if (lonLatArr.length === 1) {
            let isMatchOne = this.matchFormatLonLatToDegOne(lonLatArr[0])
            if (!isMatchOne) {
              this.errorMsg = "第" + (i + 1) + "个转换中," + this.errorMsg
              return;
            }
            let resultLineOne = this.lonLatToDegHandle(lonLatArr[0])
            resultArr.push(resultLineOne)
            //两个转换度
          } else {
            let isMatchTwo = this.matchFormatLonLatToDegTwo(lonLatArr[0], lonLatArr[1])
            if (!isMatchTwo) {
              this.errorMsg = "第" + (i + 1) + "个转换中," + this.errorMsg
              return
            }
            let resultLineTwo = this.lonLatToDegHandle(lonLatArr[0]) + "," + this.lonLatToDegHandle(lonLatArr[1])
            resultArr.push(resultLineTwo)
          }


        }
        //度转经纬度
      } else {
        for (let i = 0; i < textareaLines.length; i++) {
          let lonLatArr = textareaLines[i].split(/[，,\s]+/)
          //一个转换度
          if (lonLatArr.length === 1) {
            let isMatchOne = this.matchFormatDegToLonLatOne(lonLatArr[0]);
            if (!isMatchOne) {
              this.errorMsg = "第" + (i + 1) + "个转换中," + this.errorMsg
              return;
            }
            let resultLineOne = this.DegToLonLatHandle(isMatchOne[1], isMatchOne[2], isMatchOne[3])
            resultArr.push(resultLineOne)
            //两个转换度
          } else {
            let isMatchTwo = this.matchFormatDegToLonLat(lonLatArr[0], lonLatArr[1])
            if (!isMatchTwo) {
              this.errorMsg = "第" + (i + 1) + "个转换中," + this.errorMsg
              return
            }
            let resultLine = this.DegToLonLatHandle(isMatchTwo[0][1], isMatchTwo[0][2], isMatchTwo[0][3]) + "," + this.DegToLonLatHandle(isMatchTwo[1][1], isMatchTwo[1][2], isMatchTwo[1][3])
            resultArr.push(resultLine)
          }

        }
      }


      this.rightText = resultArr.join('\n');
    },
    //经纬度转度分秒判断格式(单个)
    matchFormatLonLatToDegOne(degStr) {
      if (degStr === null || degStr === undefined || degStr.trim() === "") {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (isNaN(Number(degStr))) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (degStr > 180 || degStr < -180) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      return true
    },
    // 经纬度转度分秒判断格式(两个)
    matchFormatLonLatToDegTwo(degStrOne, degStrTwo) {
      if (degStrOne === null || degStrOne === undefined || degStrOne.trim() === "") {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (degStrTwo === null || degStrTwo === undefined || degStrTwo.trim() === "") {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (isNaN(Number(degStrOne))) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (isNaN(Number(degStrTwo))) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (degStrOne > 180 || degStrOne < -180) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if (degStrTwo > 180 || degStrTwo < -180) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      if ((degStrOne > 90 && degStrTwo > 90)
          || (degStrOne < -90 && degStrTwo < -90)
          || (degStrOne > 90 && degStrTwo < -90)
          || (degStrOne < -90 && degStrTwo > 90)) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }
      return true;
    },
    // 经纬度转度分秒计算
    lonLatToDegHandle(decimalStr) {
      let decimal = new BigNumber(decimalStr);
      let decimalAbs = decimal.abs();
      let degrees = decimalAbs.integerValue(BigNumber.ROUND_DOWN);
      let minutesDecimal = (decimalAbs.minus(degrees)).times(60);
      let minutes = minutesDecimal.integerValue(BigNumber.ROUND_DOWN);
      let seconds = (minutesDecimal.minus(minutes)).times(60);
      let result = `${degrees.toString()}°${minutes.toString()}′${seconds.toString()}"`;
      return decimal < 0 ? "-" + result : result;
    },
    // 度分秒转经纬度格式判断(单个)
    matchFormatDegToLonLatOne(degStr) {
      if (degStr === null || degStr === undefined || degStr.trim() === "") {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false;
      }
      const regex = /(-?\d+)°(\d+)[′'](\d+(?:\.\d+)?)[″"]/;
      let matchOne = degStr.match(regex);
      if (!matchOne) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      let longitudeDegreesValue = parseInt(matchOne[1])
      let longitudeMinutesValue = parseInt(matchOne[2])
      let longitudeSecondsValue = parseFloat(matchOne[3])

      if (longitudeDegreesValue < -180 || longitudeDegreesValue > 180) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if (longitudeMinutesValue < 0 || longitudeMinutesValue > 59) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if (longitudeSecondsValue < 0 || longitudeSecondsValue >= 60) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if ((longitudeDegreesValue === -180 && longitudeSecondsValue > 0) ||
          (longitudeDegreesValue === -180 && longitudeMinutesValue > 0) ||
          (longitudeDegreesValue === 180 && longitudeSecondsValue > 0) ||
          (longitudeDegreesValue === 180 && longitudeMinutesValue > 0)) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      return matchOne
    },
    // 度分秒转经纬度格式判断(双个)
    matchFormatDegToLonLat(degStrOne, degStrTwo) {
      if (degStrOne === null || degStrOne === undefined || degStrOne.trim() === "") {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false;
      }
      if (degStrTwo === null || degStrTwo === undefined || degStrTwo.trim() === "") {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false;
      }
      const regex = /(-?\d+)°(\d+)[′'](\d+(?:\.\d+)?)[″"]/;
      let matchOne = degStrOne.match(regex);
      let matchTwo = degStrTwo.match(regex);

      if (!matchOne) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if (!matchTwo) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      let DegreesValueOne = parseInt(matchOne[1])
      let MinutesValueOne = parseInt(matchOne[2])
      let SecondsValueOne = parseFloat(matchOne[3])

      let DegreesValueTwo = parseInt(matchTwo[1])
      let MinutesValueTwo = parseInt(matchTwo[2])
      let SecondsValueTwo = parseFloat(matchTwo[3])

      if (DegreesValueOne < -180 || DegreesValueOne > 180) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if (DegreesValueTwo < -180 || DegreesValueTwo > 180) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }

      if (MinutesValueOne < 0 || MinutesValueOne > 59) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if (MinutesValueTwo < 0 || MinutesValueTwo > 59) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }

      if (SecondsValueOne < 0 || SecondsValueOne >= 60) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }

      if (SecondsValueTwo < 0 || SecondsValueTwo >= 60) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }

      if ((DegreesValueOne === -180 && SecondsValueOne > 0) ||
          (DegreesValueOne === -180 && MinutesValueOne > 0) ||
          (DegreesValueOne === 180 && SecondsValueOne > 0) ||
          (DegreesValueOne === 180 && MinutesValueOne > 0)) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if ((DegreesValueTwo === -90 && MinutesValueTwo > 0) ||
          (DegreesValueTwo === -90 && SecondsValueTwo > 0) ||
          (DegreesValueTwo === 90 && MinutesValueTwo > 0) ||
          (DegreesValueTwo === 90 && SecondsValueTwo > 0)) {
        this.errorMsg = "请输入度分秒格式正确的经纬度"
        return false
      }
      if ((DegreesValueOne > 90 && DegreesValueTwo > 90)
          || (DegreesValueOne < -90 && DegreesValueTwo < -90)
          || (DegreesValueOne > 90 && DegreesValueTwo < -90)
          || (DegreesValueOne < -90 && DegreesValueTwo > 90)) {
        this.errorMsg = "请输入度格式正确的经纬度"
        return false;
      }

      let lonLatLineArr = []
      lonLatLineArr.push(matchOne)
      lonLatLineArr.push(matchTwo)
      return lonLatLineArr
    },
    // 度分秒转经纬度计算
    DegToLonLatHandle(degrees, minutes, seconds) {
      let numberAbs = Math.abs(parseFloat(degrees));
      let numberAbsBig = new BigNumber(numberAbs);
      let minutesBig = new BigNumber(minutes);
      let secondsBig = new BigNumber(seconds);
      let result = numberAbsBig.plus((minutesBig.div(60))).plus(secondsBig.div(3600));
      return degrees < 0 ? "-" + result : result;
    },

    // 点击复制粘贴单位符号
    addUnitDot(ele) {
      this.copyText(ele)
      if (this.leftText == null) {
        this.leftText = ele
        return
      }
      if (this.leftTextAreaSelect == null) {
        let leftTextarea = document.querySelector(".left_convert .text_div");
        this.leftTextAreaSelect = leftTextarea.selectionStart;
      }
      if (this.leftTextAreaSelect != null) {
        this.leftText = this.leftText.substring(0, this.leftTextAreaSelect) + ele + this.leftText.substring(this.leftTextAreaSelect);
        this.leftTextAreaSelect++
      }

    },
    copyResult() {
      if (this.rightText == null) {
        return
      }
      this.copyText(this.rightText)
    },
    copyText(msg) {
      return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(msg).then(
            () => {
              resolve(true)
              message({
                message: "复制成功",
                type: "success",
                duration: 1200
              })
            },
            () => {
              reject(new Error('复制失败'))
            }
        )
      })
    }

  }

}
</script>

<style scoped>
img {
  -webkit-user-drag: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.app_con {
  background-color: #EFEFF4;
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
  justify-content: center;
  height: 50px;
  font-weight: bold;
  font-size: 16px;
  background-color: #FFF;
}

.head .head_nav {
  display: inline-block;
  height: 50px;
  line-height: 40px;
}

.active {
  position: relative;
  color: #007AFF;
}

.active::before {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
  border-bottom: 4px solid #007AFF; /* 设置虚拟边框样式 */
  border-radius: 4px;
}

.head .logo {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 36px;
}

.banner_con {
  position: relative;
  margin-bottom: -10px;
}

.banner_con .banner_img {
  width: 100%;
}

.banner_con .banner_controller {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.banner_con .banner_controller:first-of-type {
  left: 20px;
}

.banner_con .banner_controller:last-of-type {
  right: 20px;
}

.convert_con {
  flex: 1;
  background-color: #EFEFF4;
  display: flex;
  justify-content: center;
  padding: 22px 0 18px 0;
}

.convert_con .convert_center {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
  width: 70%;
  border-radius: 4px;
  padding: 20px 40px 10px 40px;
  display: flex;
  flex-direction: column;
}

.convert_center .convert_title{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.convert_center .convert_introduce{
  text-align: center;
  font-size: 12px;
  color: #6D7278;
  margin-top: 10px;
}

.convert_center .convert_div{
  flex: 1;
  display: flex;
}

.left_convert,.right_convert{
  width: 46%;
  display: flex;
  flex-direction: column;
}

.convert_div .center_buttons{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 13px;
  margin-top: 40px;
}
.center_buttons .switch_div,.center_buttons .clear_div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center_buttons img{
  margin-bottom: 5px;
}
.convert_div .unit_line{
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 20px;
}
.unit_line .dfm_div span{
  display: inline-block;
  margin-left: 10px;
  border: 1px solid #E5E5EA;
  width: 20px;
  height: 20px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.unit_line .copy_unit_tip{
  font-weight: normal;
  font-size: 14px;
  color: #C7C7CC;
  margin-left: 10px;
}


.convert_div .text_div{
  flex: 1;
  border: 1px solid #E5E5EA;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  outline:none;
}
.convert_div .text_div::placeholder{
  color: #C7C7CC;
  font-size: 14px;
  line-height: 20px;
}

.error_tip{
  color: #E02020 ;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;
  height: 12px;
}
.convert_div .error_border{
  border: 1px solid #E02020;
}

.result_buttons{
  margin-top: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get_result, .copy_result {
  height: 35px;
  border: none;
  border-radius: 8px;

}

.get_result {
  background-color: #007AFF;
  opacity: 0.5;
  width: 20%;
  margin-right: 20px;
  color: #FFFFFF;
  pointer-events: none;
}

.result_buttons .able {
  opacity: 1;
  pointer-events: auto;
}

.copy_result_div {
  width: 15%;
  position: relative;
}

.copy_result {
  width: 100%;
  background-color: #FFFFFF;
  color: #007AFF;
  border: 1px solid #007AFF;
}


/*底部*/
.foot_con {
  background-color: #FFF;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6D7278;
  font-size: 12px;
  justify-content: center;
}

.foot_con .first_fool_line,.foot_con .second_fool_line{
  display: flex;
  align-items: center;
}
.foot_con .first_fool_line p, .foot_con .second_fool_line p {
  margin-left: 15px;
}

.foot_con .first_fool_line img {
  vertical-align: middle;
}

.foot_con a {
  color: #6D7278;
  text-decoration: none;
}

.foot_con a:hover {
  color: #007AFF;
}

</style>