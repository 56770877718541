import {createRouter, createWebHashHistory} from 'vue-router'
import UnitConvert from '../views/UnitConvert.vue'

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: '/index',
    name: 'UnitConvert',
    component: UnitConvert
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
