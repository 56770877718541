import {ElMessage} from 'element-plus'

//重新设置ElMessage的最大显示个数为3
const instances = []
const newMessage = options => {
    if (instances.length <= 2) {
        instances.push(ElMessage(options))
    } else {
        instances[0].close()
        instances.shift()
        instances.push(ElMessage(options))
    }
}
const messageList = ['error', 'success', 'info', 'warning']
messageList.forEach(type => {
    newMessage[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options
            }
        }
        options.type = type
        return newMessage(options)
    }
})
export const message = newMessage
